import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import "./App.css"; // Assuming you have a CSS file called App.css in the same directory
import axios from 'axios';
import Button from '@mui/material/Button';
import SideScrollingForecast from "./SideScrollingForecast";
import RadarControl from "./RadarImage/RadarControl";


Modal.setAppElement("#root"); // Set the root element for accessibility

function App() {
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [coords, setCoords] = useState(null)
    const [cloudFlareLocation, setCloudFlareLocation] = useState(null);
    const [nearestTown, setNearestTown] = useState("");
    const [forecastURL, setForecastURL] = useState(''); //contains endpoint from noaa, contains the gridpoint URLs
    const [forecastData, setForecastData] = useState(null);
    const [pointsData,setPointsData] = useState(null); //contains your radar station id, and gridpoints data


    const handleSettingsOpen = () => {
        setShowSettings(true);
    };

    const handleSettingsClose = () => {
        setShowSettings(false);
    };

    const toggleAccordion = () => {
        setAccordionOpen(!accordionOpen);
    };

    //Coords logger, logs every time coords is updated
    useEffect(() => {
        console.log("coords logger, coords is", coords);
    }, [coords]);
    //Forecast JSON logger, logs forecastData every time it is updated
    useEffect(() => {
        console.log("forecast Data logger, forecast data is", forecastData);
    }, [forecastData]);
    useEffect(() => {
        console.log("forecast url is", forecastURL);
    }, [forecastURL]
    );





    //get the users location from cloudflare worker, if good, set lat long based on that
    useEffect(() => {
        async function fetchCloudFlareLocation() {
            try {
                const response = await fetch(
                    "https://geolocation-service.gabriel-killhour.workers.dev/" 
                );
                const data = await response.json();
                setCloudFlareLocation(data);
                setCoords({ "latitude": data.latitude, "longitude": data.longitude })
            } catch (error) {
                console.error("Error fetching cloudflare location data:", error);
            }
        }
        fetchCloudFlareLocation();
    }, []);

    useEffect(() => {
        // Check if geolocation is supported by the browser
        if (cloudFlareLocation != null) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    console.log("your position is lat:", latitude, " long:", longitude)
                    setCoords({ "latitude": latitude, "longitude": longitude });
                }, error => {
                    console.error("Error getting geolocation:", error);
                });
            } else {
                console.error("Geolocation is not supported by your browser.");
            }
        }
    }, [cloudFlareLocation]);

    // Detect nearest town if user has geolocation - reverse geocoding api
    useEffect(() => {
        // Check if the coordinates object is available
        if (coords && coords.latitude && coords.longitude) {
            detectNearestTown(coords);
        } else {
            console.log("Reverse Geocoding Coordinates are not available");
        }
    }, [coords]);

    //Hit the links URL based on coords, will update every time coords is updated.
    useEffect(() => {
        if (coords && coords.latitude && coords.longitude) {
            const getGridEndpoint = async () => {
                try {
                    const pointsURL = `https://api.weather.gov/points/${coords.latitude},${coords.longitude}`;
                    const pointsData = await fetchData(pointsURL);
                    if (pointsData && pointsData.properties && pointsData.properties.forecast) {
                        setForecastURL(pointsData.properties.forecast);
                        setPointsData(pointsData);
                    } else {
                        console.error('Failed to get forecast URL from the API');
                    }
                } catch (error) {
                    console.error('Error getting grid endpoint:', error);
                }
            };
            getGridEndpoint();
        }
    }, [coords]);

    //Fetch the forecast Data from the forecast URL Provided previously by the API
    useEffect(() => {
        if (forecastURL) {
            const fetchForecastData = async () => {
                try {
                    const data = await fetchData(forecastURL);
                    setForecastData(data);
                } catch (error) {
                    console.error('Error fetching forecast data:', error);
                }
            };
            fetchForecastData();
        }
    }, [forecastURL]);

    // useMemo will recompute the periodsToShow only when forecastData changes only use the first 12
    const periodsToShow = useMemo(() => {
        if (forecastData) {
            // Slice the first 12 periods or fewer if there aren't enough
            return forecastData.properties.periods.slice(0, 12);
        }
        return []; // Return an empty array if no forecastData is available
    }, [forecastData]);

    //-------
    //Utility Functions
    //-------

    //Used for fetching data from the NOAA Forecast API currently
    const fetchData = async (url) => {
        try {
            const response = await axios.get(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    const detectNearestTown = (coordinates) => {
        // Perform reverse geocoding using the API
        // Replace the 'your-api-key' with your actual API key
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=AIzaSyBguWMDOJHyNA-KzlBlQdmWDA7zo78RHng`)
            .then(response => response.json())
            .then(data => {
                console.log("a geocoding API request was sent...")
                // Check if the response status is OK
                if (data.status === "OK") {
                    const results = data.results;

                    // Find the address component with the type "locality" or "administrative_area_level_2"
                    let nearestTown = "";
                    for (let result of results) {
                        const addressComponents = result.address_components;
                        const types = result.types;

                        if (types.includes("locality") || types.includes("administrative_area_level_2")) {
                            nearestTown = addressComponents[0].long_name;
                            break;
                        }
                    }
                    // Update the nearest town state hook
                    setNearestTown(nearestTown);
                } else {
                    console.log("Unable to retrieve address information for reverse geocoding");
                }
            })
            .catch(error => {
                console.error("Error fetching reverse geocoding data:", error);
            });
    };



    return (
        <div className="App">
            {/* <Seo></Seo> */}
            <header className="App-header">
                {/* <img src="facebook-logo.png" alt="Facebook Logo" className="fb-logo" /> */}
                <h1>Live Radar Page</h1>
                <p>
                    Local Weather & Radar Loop for my location {nearestTown}
                    <button
                        aria-label="Open Settings"
                        className="settings-button"
                        onClick={handleSettingsOpen}
                    >
                        <FontAwesomeIcon icon={faCog} />
                    </button>
                </p>
            </header>

            <div className="main">
                <div className="accordion-container">
                    <div className="accordion-header" onClick={toggleAccordion}>
                        <h2>
                            {/* <FontAwesomeIcon
                                icon={accordionOpen ? faChevronDown : faChevronRight}
                            /> */}
                        </h2>
                    </div>
                    {accordionOpen && (
                        <div className="accordion-content">
                            <h3>How the Site Works</h3>
                            <p>This site is designed to use location services to detect your position, and serve a radar image
                                in real time, along with an optional weather forecast.
                            </p>
                            <h3>Doppler Radar</h3>
                            <p>
                                Doppler radar can detect rain levels, hail, sleet, snow, flash floods,
                                severe thunderstorms, and tornados, providing crucial
                                weather data to coastal navigators, airplanes, farmers, commuters, local authorities, and anyone else who needs it.
                            </p>
                            <h3>How to Read Radar</h3>
                            <p>Radar loop for a region will show Rain Levels, for your region with varying severity, measured in DBZ reflectivity</p>
                            <h3>Reflectivity</h3>
                            Green indicates light rain at 35 dBZ. Yellow - between 35 and 50 dBZ suggests moderate rain, while red indicate heavy rain above above 50 dBZ. If the reflectivity exceeds about 55 dBZ, it typically indicates the presence of hail.
                            <h3>Data Sources</h3>
                            <p>NOAA Operates 159 weather radar stations throughout the US to compile these radar images. Images are provided by through the Weather.gov web api.</p>
                            <h3>Do I need to Refresh the Page?</h3>
                            <p>No, Live Radar for your region within the USA will automatically update without refreshing the page. This dashboard was designed for wall displays and digital signage.</p>
                            <h3>Is this site available outside the United States?</h3>
                            <p>No, Currently this site provides radar data for the Continental United States, Guam, Hawaii, Alaska, and Puerto Rico</p>
                            <b>If you have any issues, please contact us. We are excited to provide this service, curious to know of your use case & hear new feature requests.</b>

                        </div>
                    )}
                </div>

                <div className="forecast-supercontainer">
                    {forecastData ? (
                        <SideScrollingForecast periods={periodsToShow} />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
                <RadarControl coords={coords} pointsData={pointsData} />
            </div>

            <Modal
                isOpen={showSettings}
                onRequestClose={handleSettingsClose}
                contentLabel="Settings"
                className="modal"
                overlayClassName="modal-overlay"
            >
                <button className="close" onClick={handleSettingsClose}>
                    &times;
                </button>
                {/* <h2>Settings</h2> */}
                {/* <p>Settings content goes here...</p> */}
                {/* Other content */}
                <iframe
                    title="Survey"
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeKDLw0ZuGNL6XUBMENyA1iI9jGBpZUJa2sNQHyXzbsvX2Ylw/viewform?embedded=true"
                    className="modal-iframe"
                    frameBorder="0"
                    scrolling="yes"  // Add this attribute
                    allowFullScreen
                >
                    Loading…
                </iframe>
            </Modal>

            <footer className="App-footer">
                <div className="Footer-text">
                    <p> <i>Data & images on <b>LiveLocalRadar.com</b> are provided without warranty or liability. 
                        A VHF Radio with NOAA Weather Channels is recommended for coastal mariners. </i></p>
                    <p>
                        <Button onClick={handleSettingsOpen} variant="text" className="feedback-button"
                        >Provide Site Feedback</Button>
                    </p>
                </div>
            </footer>

        </div>
    );
}



export default App;
