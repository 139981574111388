
const regionBoundaries = {
    "Pacific Northwest": { latMin: 40, latMax: 49, lonMin: -125, lonMax: -113.5, urlName: "PACNORTHWEST" },
    "North Rockies": { latMin: 44, latMax: 49, lonMin: -116, lonMax: -104, urlName: "NORTHROCKIES" },
    "Upper Mississippi Valley": { latMin: 41, latMax: 48, lonMin: -104, lonMax: -87, urlName: "UPPERMISSVLY" },
    "Central Great Lakes": { latMin: 38, latMax: 48, lonMin: -89, lonMax: -78.5, urlName: "CENTGRLAKES" },
    "Northeast": { latMin: 36, latMax: 49, lonMin: -87, lonMax: -66, urlName: "NORTHEAST" },
    "Pacific Southwest": { latMin: 32, latMax: 42, lonMin: -124, lonMax: -113, urlName: "PACSOUTHWEST" },
    "Southern Rockies": { latMin: 34, latMax: 42, lonMin: -109, lonMax: -100, urlName: "SOUTHROCKIES" },
    "Southern Plains": { latMin: 32, latMax: 40, lonMin: -109, lonMax: -92, urlName: "SOUTHPLAINS" },
    "Southern Mississippi Valley": { latMin: 29, latMax: 37, lonMin: -94, lonMax: -82, urlName: "SOUTHMISSVLY" },
    "Southeast": { latMin: 24, latMax: 36, lonMin: -94, lonMax: -75, urlName: "SOUTHEAST" },
    "Alaska": { latMin: 54, latMax: 71, lonMin: -180, lonMax: -129, urlName: "ALASKA" },
    "Hawaii": { latMin: 15, latMax: 23.5, lonMin: -163, lonMax: -150, urlName: "HAWAII" },
    "Guam": { latMin: 13, latMax: 13.8, lonMin: 144.6, lonMax: 145.01, urlName: "GUAM" },
    "Puerto Rico": { latMin: 17.8, latMax: 18.5, lonMin: -67.5, lonMax: -65.0, urlName: "TJUA" },
    "Continental US": { latMin: null, latMax: null, lonMin: null, lonMax: null, urlName: "CONUS" },
};

export default regionBoundaries;

