import React from 'react';


const ForecastBox = ({ period }) => {
    // Check if probability of percipitation is null, if it is, show 0%
    // const probabilityOfPrecipitation = period.probabilityOfPrecipitation.value
    //     ? `${period.probabilityOfPrecipitation.value}%`
    //     : '0%';

    return (
        <div className="forecast-box">
            <div className="forecast-box-header">
                <div className="forecast-name">{period.name}</div>
                <div className="forecast-temperature">
                    {period.temperature}&deg;{period.temperatureUnit}
                </div>
            </div>
            <div className="forecast-icon">
                <img src={period.icon} alt={period.shortForecast} />
            </div>
            <div className="forecast-details">
                <div className="forecast-precipitation">
                    {/* Precipitation: {probabilityOfPrecipitation} */}
                </div>
                <div className="forecast-description">{period.shortForecast}.</div>
                <div className="forecast-wind">
                    Wind from {period.windDirection} at {period.windSpeed}
                </div>
            </div>
        </div>
    );
};

const SideScrollingForecast = ({ periods }) => {
    return (
        <div className="side-scrolling-forecast-container">
            <div className="side-scrolling-forecast">
                {periods.map((period) => (
                    <ForecastBox key={period.number} period={period} />
                ))}
            </div>
        </div>
    );
};
export default SideScrollingForecast